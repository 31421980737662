import type { TransitionEvents } from '@headlessui/react'
import { Dialog, Transition } from '@headlessui/react'
import * as React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { classNames } from '../utils'

export interface Props {
  noCloseButton?: boolean
  afterLeave?: TransitionEvents['afterLeave']
  onClose(): void
  children: React.ReactNode
  isOpen: boolean
  title: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export default function BaseDialog(props: Props) {
  return (
    <Transition appear show={props.isOpen} as={React.Fragment}>
      <Dialog className="relative z-10" onClose={props.onClose} as="div">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-50"
            aria-hidden="true"
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              afterLeave={props.afterLeave}
            >
              <Dialog.Panel
                className={classNames(
                  {
                    sm: 'max-w-sm',
                    md: 'max-w-md',
                    lg: 'max-w-lg',
                    xl: 'max-w-xl',
                  }[props.size || 'xl'],
                  'w-full  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-2xl transition-all'
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-slate-600"
                >
                  {props.title}
                </Dialog.Title>

                {!props.noCloseButton && (
                  <button
                    type="button"
                    onClick={props.onClose}
                    className="absolute right-6 top-6 rounded-sm outline-none ring-offset-2 hover:text-sky-600 focus-visible:ring-2"
                    aria-label="close"
                  >
                    <XMarkIcon className="block h-5 w-5" aria-hidden="true" />
                  </button>
                )}

                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
