import { setupWorker } from 'msw'

import { handlers as advisorRequestsHandlers } from './advisorRequestsHandlers'
import { handlers as paymentSetupHandlers } from './paymentSetupHandlers'
import { handlers as documentsHandlers } from './documentsHandlers'
import { handlers as paymentsHandlers } from './paymentsHandlers'
import { handlers as profileHandlers } from './profileHandlers'
import { handlers as timeoffHandlers } from './timeoffHandlers'
import { handlers as teamHandlers } from './teamHandlers'

// This configures a Service Worker with the given request handlers.
export const worker = setupWorker(
  ...advisorRequestsHandlers,
  ...paymentSetupHandlers,
  ...documentsHandlers,
  ...paymentsHandlers,
  ...profileHandlers,
  ...timeoffHandlers,
  ...teamHandlers
)
