import { useForm } from 'react-hook-form'
import * as React from 'react'

import { requestTaxAdvisorSession } from '../api/taxAdvisorRequestsApi'
import { useToasts } from './ToastsProvider'
import BaseDialog from './BaseDialog'
import TextField from './TextField'
import Spinner from './Spinner'

interface Props {
  onClose(): void
  isOpen: boolean
}

type ContactForm = {
  message: string
}

const defaultValues = {
  message: '',
}

export default function AdvisorSessionDialog({ isOpen, onClose }: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const { addToast } = useToasts()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<ContactForm>({ defaultValues })

  const handleClose = () => {
    onClose()
    reset()
  }

  const onSubmit = (data: ContactForm) => {
    setIsSubmitting(true)
    requestTaxAdvisorSession(data)
      .then(handleClose)
      .then(() => {
        // give some time for closing dialog animation to settle
        setTimeout(() => {
          addToast('Session requested', { variant: 'success' })
        }, 1000)
      })
      .catch((err) => addToast(err.message, { variant: 'danger' }))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <BaseDialog
      onClose={handleClose}
      isOpen={isOpen}
      title="Meet with our Tax Advisor"
    >
      <p className="mt-4 max-w-prose text-sm text-slate-500">
        Lorem ipsum dolor sit amet, clla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
        id est laborum.
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-6 max-w-prose">
        <TextField
          label={
            <>
              Message{' '}
              <span className="ml-1 text-xs italic text-slate-500">
                (optional)
              </span>
            </>
          }
          multiline
          rows={4}
          errors={errors}
          {...register('message')}
        />

        <div className="mt-2 flex gap-4">
          <button
            className="text-md mt-4 block rounded-md bg-sky-500 px-8 py-2 font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2 disabled:bg-sky-300 md:w-auto"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting && (
              <span className="relative top-0.5 mr-2 inline-block">
                <Spinner reverse size="small" />
              </span>
            )}
            {isSubmitting ? 'Sending...' : 'Send'}
          </button>
          <button
            className="mt-4 block rounded-md px-4 py-2 text-slate-500 underline decoration-slate-400 underline-offset-1 outline-none hover:text-slate-700 focus-visible:ring-2 disabled:text-slate-300 md:w-auto"
            disabled={isSubmitting}
            onClick={handleClose}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </BaseDialog>
  )
}
