import { TaxAdvisorSessionRequest } from '../types'
import { apiBaseUrl, tokenStorageKey } from '../config'
import { checkAuth } from '../utils'

export async function requestTaxAdvisorSession(
  body: TaxAdvisorSessionRequest
): Promise<null> {
  return fetch(apiBaseUrl + '/tax-advisor-request', {
    method: 'POST',
    headers: {
      Authorization: window.localStorage[tokenStorageKey],
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then(checkAuth)
    .then(async (res) => {
      if (!res.ok) throw new Error((await res.json()).message)
      return null
    })
}
