import { SubmitHandler, useForm } from 'react-hook-form'
import * as React from 'react'

import { useToasts } from './ToastsProvider'
import TextField from './TextField'
import Spinner from './Spinner'

import { Profile } from '../types'

export type FormData = Pick<
  Profile,
  'name' | 'address' | 'email' | 'southEmail' | 'linkedin' | 'whatsapp'
> & { birthdate: string }

interface Props {
  defaultValues: FormData
  onSubmit(formData: FormData): Promise<Profile | undefined>
}

const required = 'This field is required'

export default function ProfileForm(props: Props) {
  const { addToast } = useToasts()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: props.defaultValues })

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const onSubmit: SubmitHandler<FormData> = (formData) => {
    setError(null)
    setIsSubmitting(true)
    props
      .onSubmit(formData)
      .then(() => addToast('Profile updated', { variant: 'success' }))
      .catch((err) => setError(err.message))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-6 max-w-3xl">
      <div className="mt-2 sm:columns-2">
        <TextField
          readOnly
          disabled
          label="Full name"
          errors={errors}
          {...register('name', { required })}
        />

        <TextField
          className="mt-2 sm:mt-0"
          readOnly
          disabled
          label="Southteams e-mail"
          errors={errors}
          type="email"
          {...register('southEmail', { required })}
        />
      </div>

      <div className="mt-2 sm:columns-2">
        <TextField
          label="Your birthday"
          errors={errors}
          type="date"
          {...register('birthdate', { required })}
        />

        <TextField
          className="mt-2 sm:mt-0"
          label="Personal e-mail"
          errors={errors}
          type="email"
          {...register('email', { required })}
        />
      </div>

      <div className="mt-2 lg:columns-2">
        <TextField
          label="LinkedIn URL"
          errors={errors}
          type="url"
          {...register('linkedin', { required })}
        />

        <TextField
          className="mt-2 lg:mt-0"
          label="WhatsApp number"
          errors={errors}
          type="tel"
          {...register('whatsapp', { required })}
        />
      </div>

      <TextField
        className="mt-2"
        label="Your address"
        multiline
        rows={4}
        errors={errors}
        {...register('address', { required })}
      />

      {error && <div className="mt-4 text-sm text-red-600">{error}</div>}

      <button
        className="text-md mt-4 block rounded-md bg-sky-500 px-10 py-3 font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2  disabled:bg-sky-300 md:w-auto"
        disabled={isSubmitting}
        type="submit"
      >
        {isSubmitting && (
          <span className="relative top-0.5 -ml-4 mr-2 inline-block">
            <Spinner reverse size="small" />
          </span>
        )}
        {isSubmitting ? 'Saving...' : 'Save'}
      </button>
    </form>
  )
}
