import { checkAuth, validateResponse } from '../utils'
import { Profile, profileSchema } from '../types'
import { GENERIC_ERROR_MESSAGE } from './common'
import { apiBaseUrl, tokenStorageKey } from '../config'

export async function getProfile(): Promise<Profile> {
  return fetch(apiBaseUrl + '/profile', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(profileSchema))
}

export async function updateProfile(body: Partial<Profile>): Promise<Profile> {
  return fetch(apiBaseUrl + '/profile', {
    method: 'PATCH',
    headers: {
      Authorization: window.localStorage[tokenStorageKey],
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then(checkAuth)
    .then(async (res) => {
      if (!res.ok) {
        let message
        try {
          message = (await res.json()).message
        } catch (err) {
          message = GENERIC_ERROR_MESSAGE
        }

        throw new Error(message)
      }
      return res.json()
    })
}
