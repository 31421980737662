import { SubmitHandler, useForm } from 'react-hook-form'
import * as React from 'react'

import { useToasts } from './ToastsProvider'
import TextField from './TextField'
import Spinner from './Spinner'

import { CandidateReferral } from '../types'
import { sendReferral } from '../api/referralsApi'

export type FormData = CandidateReferral

interface Props {}

const required = 'This field is required'

export default function ReferralForm(props: Props) {
  const { addToast } = useToasts()

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const onSubmit: SubmitHandler<FormData> = (formData) => {
    setError(null)
    setIsSubmitting(true)
    sendReferral(formData)
      .then(() => {
        addToast('Referral sent', { variant: 'success' })
        reset()
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="mt-4 gap-8 sm:grid sm:grid-cols-2">
        <TextField
          label="Candidate name"
          errors={errors}
          {...register('name', { required })}
        />
      </div>

      <div className="mt-2 gap-8 sm:grid sm:grid-cols-2">
        <TextField
          className="mt-2 sm:mt-0"
          label="E-mail"
          errors={errors}
          type="email"
          {...register('email', { required })}
        />

        <TextField
          className="mt-2 lg:mt-0"
          label="WhatsApp number"
          errors={errors}
          type="tel"
          helpText="Optional"
          {...register('whatsapp')}
        />
      </div>

      <TextField
        className="-mt-4"
        label="LinkedIn URL"
        errors={errors}
        type="url"
        {...register('linkedin', { required })}
      />

      <TextField
        label="Resume URL"
        errors={errors}
        type="url"
        {...register('resume', { required })}
      />

      <TextField
        className="mt-2 sm:mt-0"
        label="Candidate summary"
        errors={errors}
        helpText="e.g. React.js, Fullstack, DevOps, etc."
        {...register('summary', { required })}
      />

      <TextField
        label="How do you know this person?"
        helpText="Feel free to add any detail that you think is relevant."
        multiline
        rows={4}
        errors={errors}
        {...register('comments', { required })}
      />

      {error && <div className="mt-4 text-sm text-red-600">{error}</div>}

      <button
        className="text-md mt-4 block rounded-md bg-sky-500 px-10 py-3 font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2  disabled:bg-sky-300 md:w-auto"
        disabled={isSubmitting}
        type="submit"
      >
        {isSubmitting && (
          <span className="relative top-0.5 -ml-4 mr-2 inline-block">
            <Spinner reverse size="small" />
          </span>
        )}
        {isSubmitting ? 'Sending...' : 'Send referral'}
      </button>
    </form>
  )
}
