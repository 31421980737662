import { GoogleOAuthProvider } from '@react-oauth/google'
import { ValidationError } from 'zod-validation-error'
import { Routes, Route } from 'react-router-dom'
import { SWRConfig } from 'swr'
import * as React from 'react'

import { ENV_VARS } from './env'
import PaymentsHistoryPage from './pages/PaymentsHistoryPage'
import TimeOffOverviewPage from './pages/TimeOffOverviewPage'
import TimeOffRequestPage from './pages/TimeOffRequestPage'
import TimeOffHistoryPage from './pages/TimeOffHistoryPage'
import CreateAccountPage from './pages/CreateAccountPage'
import PaymentSetupPage from './pages/PaymentSetupPage'
import { useToasts } from './components/ToastsProvider'
import DashboardPage from './pages/DashboardPage'
import DocumentsPage from './pages/DocumentsPage'
import InvoicingPage from './pages/InvoicingPage'
import ReferralsPage from './pages/ReferralsPage'
import ErrorOverlay from './components/ErrorOverlay'
import NotFoundPage from './pages/NotFoundPage'
import PaymentsPage from './pages/PaymentsPage'
import AccountsPage from './pages/AccountsPage'
import HolidaysPage from './pages/HolidaysPage'
import ProfilePage from './pages/ProfilePage'
import TimeOffPage from './pages/TimeOffPage'
import MocksIndex from './pages/MocksIndex'
import LoginPage from './pages/LoginPage'
import Layout from './components/Layout'

import { googleSignInClientId, useMockedApi } from './config'
import { PageError } from './types'

function App() {
  const [overlayError, setOverlayError] = React.useState<null | PageError>(null)
  const { addToast } = useToasts()

  return (
    <GoogleOAuthProvider
      onScriptLoadError={() =>
        addToast(`Couldn't load Google Sign In script`, { variant: 'danger' })
      }
      clientId={googleSignInClientId}
    >
      <SWRConfig
        value={{
          onError(error, key) {
            if (key === 'active-contract' && error.status === 404) {
              return setOverlayError(null)
            }
            if (!(error instanceof ValidationError)) {
              console.warn({ key, status: error.status, info: error.info })
            }
            if (
              (key === 'profile' && error.status === 404) ||
              error.status === 500
            ) {
              setOverlayError(error)
            }
          },
          shouldRetryOnError: (err) => !!overlayError || err.status !== 500,
          revalidateOnFocus:
            !overlayError && ENV_VARS.REACT_APP_REVALIDATE_ON_FOCUS !== 'false',
        }}
      >
        <Routes>
          {useMockedApi && <Route path="/index" element={<MocksIndex />} />}
          <Route element={<LoginPage />}>
            <Route element={<Layout />}>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/payments" element={<PaymentsPage />}>
                <Route index element={<PaymentsHistoryPage />} />
                <Route path="setup" element={<PaymentSetupPage />} />
                <Route path="accounts" element={<AccountsPage />} />
                <Route path="invoicing" element={<InvoicingPage />} />
              </Route>
              <Route
                path="/payments/accounts/new"
                element={<CreateAccountPage />}
              />
              <Route path="/timeoff" element={<TimeOffPage />}>
                <Route index element={<TimeOffOverviewPage />} />
                <Route path="request" element={<TimeOffRequestPage />} />
                <Route path="holidays" element={<HolidaysPage />} />
                <Route path="history" element={<TimeOffHistoryPage />} />
              </Route>
              <Route path="/documents" element={<DocumentsPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/referrals" element={<ReferralsPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        {overlayError && (
          <ErrorOverlay
            onDismiss={() => setOverlayError(null)}
            error={overlayError}
          />
        )}
      </SWRConfig>
    </GoogleOAuthProvider>
  )
}

export default App
