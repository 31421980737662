import type { SWRConfiguration } from 'swr'
import format from 'date-fns/format'
import useSWR from 'swr'

import { ApiDate, PageError, TimeOffRequestPreview } from '../types'
import { getTimeOffRequestPreview } from '../api/timeoffApi'

export default function useTimeOffRequestPreview(
  dates: [string, boolean][],
  options?: SWRConfiguration
) {
  const params = dates.map(
    ([timestamp]) => format(parseInt(timestamp, 10), 'yyyy-MM-dd') as ApiDate
  )
  return useSWR<TimeOffRequestPreview[], PageError>(
    ['timeoff-request-preview', params],
    params.length ? () => getTimeOffRequestPreview(params) : null,
    options
  )
}
