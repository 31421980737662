import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getPaymentSetup } from '../api/paymentSetupApi'
import { PaymentSetup } from '../types'

export default function usePaymentSetup(options?: SWRConfiguration) {
  return useSWR<PaymentSetup | null, Error>(
    'last-payment-setup',
    getPaymentSetup,
    options
  )
}
