import { rest } from 'msw'

import { apiBaseUrl } from '../config'
import { Profile } from '../types'

const fakeProfile: Profile = {
  cid: '123',
  name: 'John Doe',
  email: 'johnwdoe@example.com',
  southEmail: 'johnwdoe@southteams.com',
  whatsapp: '5491162333123',
  address: 'Foo bar baz',
  linkedin: 'https://www.linkedin.com/in/lboffi',
  birthdate: 365569200000,
  startingDate: 1616986800000,
  enableTaxAdvisorBenefit: true,
}

const getProfileHandler = rest.get<null, {}, Profile>(
  apiBaseUrl + '/profile',
  (req, res, ctx) => {
    const stored = localStorage.getItem('profile')
    const payload = stored ? JSON.parse(stored) : fakeProfile
    return res(ctx.status(200), ctx.json(payload), ctx.delay(500))
  }
)

const updateProfileHandler = rest.patch<Profile, {}, Profile>(
  apiBaseUrl + '/profile',
  (req, res, ctx) => {
    // send address as "error" to mock an error response
    if (req.body.address === 'error') {
      return res(ctx.status(400), ctx.delay(200))
    }
    localStorage.setItem('profile', JSON.stringify(req.body))
    return res(ctx.status(200), ctx.json(req.body), ctx.delay(600))
  }
)

export const handlers = [updateProfileHandler, getProfileHandler]
