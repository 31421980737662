import * as React from 'react'

import ReferralForm from '../components/ReferralForm'

export default function ReferralsPage() {
  return (
    <div className="pb-12">
      <h1 className="text-3xl">Refer a friend</h1>

      <div className="flex flex-col items-start gap-8 lg:flex-row">
        <aside className="mt-4 shrink-0 rounded-md bg-slate-100 p-4 lg:order-1 lg:max-w-[240px]">
          <h2 className="mb-3 font-bold text-slate-600">
            Our Referral Program
          </h2>
          <p className="text-sm">
            Lorem ipsum dolor sit amet, consectetur adipisicing sint occaecat
            dent, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p className="mt-3 text-sm">
            Lorem ipsum dolor sit amet, officia deserunt mollit anim id est
            laborum.
          </p>
        </aside>

        <div className="mt-2 w-full max-w-prose">
          <ReferralForm />
        </div>
      </div>
    </div>
  )
}
