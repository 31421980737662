import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import format from 'date-fns/format'

import { classNames, currencyFormat } from '../utils'
import usePayrollTransactions from '../hooks/usePayrollTransactions'
import usePaymentsBalance from '../hooks/usePaymentsBalance'
import Spinner from '../components/Spinner'

export default function PaymentsHistoryPage() {
  const transactions = usePayrollTransactions()
  const balance = usePaymentsBalance()

  return (
    <div className="max-w-2xl">
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>

      {typeof balance.data?.balance === 'number' && (
        <div className="-ml-3 mt-5 flex justify-between rounded-md bg-slate-100 px-3 py-4 font-semibold">
          Your balance <div>{currencyFormat.format(balance.data.balance)}</div>
        </div>
      )}

      {transactions.data && (
        <table className="-mx-3 mt-4 w-full text-sm">
          <thead>
            <tr className="text-slate-500">
              <th className="border-b px-3 py-1.5 text-left font-semibold">
                Date
              </th>
              <th className="border-b px-3 py-1.5 text-left font-semibold">
                Description
              </th>
              <th className="border-b px-3 py-1.5 text-right font-semibold">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.data.map((tx) => {
              return (
                <tr key={tx._id}>
                  <td className="border-b px-3 py-2 text-slate-700">
                    {tx.completedOn ? format(tx.completedOn, 'yyyy-MM-dd') : ''}
                  </td>
                  <td className="border-b px-3 py-2 text-slate-700">
                    {tx.description}
                  </td>
                  <td
                    className={classNames(
                      'border-b px-3 py-2 text-right',
                      tx.type === 'credit' && 'text-green-700',
                      tx.type === 'discount' && 'text-red-700'
                    )}
                  >
                    {currencyFormat.format(
                      tx.amount * (tx.type === 'discount' ? -1 : 1)
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}

      {transactions.isLoading && <Spinner className="mx-auto my-12" />}

      {transactions.error && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">Oops...</strong>
          <p className="mt-2">{transactions.error.message}</p>
        </div>
      )}
    </div>
  )
}
