import {
  EllipsisHorizontalIcon,
  ExclaimationCircleIcon,
  ShieldExclamationIcon,
  ShieldCheckIcon,
  GlobeAltIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/20/solid'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { removeAccount } from '../api/accountsApi'
import usePaymentSetup from '../hooks/usePaymentSetup'
import AccountDetails from '../components/AccountDetails'
import { classNames } from '../utils'
import { useToasts } from '../components/ToastsProvider'
import PayoneerLogo from '../components/PayoneerLogo'
import UsaBankIcon from '../components/UsaBankIcon'
import useAccounts from '../hooks/useAccounts'
import { Account } from '../types'
import BaseDialog from '../components/BaseDialog'
import Spinner from '../components/Spinner'
import Tooltip from '../components/Tooltip'

export default function AccountsPage() {
  const { addToast } = useToasts()
  const paymentSetup = usePaymentSetup()
  const accounts = useAccounts()

  const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(
    null
  )

  const [action, setAction] = React.useState<
    'details' | 'delete' | 'rename' | null
  >(null)

  const handleRemoveConfirmation = () => {
    const accountId = selectedAccount?._id
    if (!accountId) return
    setAction(null)
    accounts.mutate(
      async () => {
        await removeAccount(accountId)
          .then(() => addToast('Account removed', { variant: 'success' }))
          .catch((err) =>
            addToast(err.message, { variant: 'danger', autoCloseDelay: 5000 })
          )
        return undefined
      },
      {
        optimisticData: (items = []) => {
          return items.filter((item) => item._id !== accountId)
        },
        rollbackOnError: true,
        // the response from DELETE is not what we want to update the cache
        populateCache: false,
      }
    )
  }

  const accountsInUse = paymentSetup.data
    ? 'rest' in paymentSetup.data
      ? [paymentSetup.data.fixed.account, paymentSetup.data.rest]
      : [paymentSetup.data.fixed.account]
    : []

  return (
    <div className="max-w-2xl">
      <p className="text-slate-500">
        Add the digital or physical bank accounts where you want to use to
        receive your payments. In the{' '}
        <Link
          className="text-sky-600 underline underline-offset-1 outline-none hover:text-sky-700 focus-visible:ring-2"
          to="/payments/setup"
        >
          Payment setup
        </Link>{' '}
        section you can optionally split your payments into 2 accounts.
      </p>

      <div className="flex items-center justify-between gap-8">
        <p className="mt-4 text-slate-500">
          Remember accounts must be under your name.
        </p>

        {((accounts.data && accounts.data.length > 0) || accounts.error) && (
          <Link
            className="relative top-2 -mr-2 whitespace-nowrap rounded-md bg-sky-500 px-7 py-3 text-sm font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2 sm:px-5 sm:py-2 md:mr-0"
            to="new"
          >
            <PlusIcon className="relative -top-[1.5px] -my-1 -ml-2 mr-1.5 inline-block w-5" />{' '}
            Add
          </Link>
        )}
      </div>

      {!accounts.data && !accounts.error && (
        <Spinner className="mx-auto my-12" />
      )}

      {accounts.error && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">Oops...</strong>
          <p className="mt-2">{accounts.error.message}</p>
        </div>
      )}

      {accounts.data ? (
        accounts.data.length > 0 ? (
          <ul className="-mx-4 mt-6 divide-y md:mx-0">
            {accounts.data
              // always show Payoneer account on top
              .sort((a) => (a.type === 'payoneer' ? -1 : 1))
              .map((account) => {
                const isInUse = accountsInUse.includes(account._id)
                return (
                  <li
                    className="flex items-baseline justify-between gap-2 px-1 py-4 md:gap-6 md:px-0"
                    key={account._id}
                  >
                    <div className="grow self-center whitespace-nowrap text-sm">
                      {account.type === 'payoneer' && (
                        <PayoneerLogo className="ml-0.25 inline-block w-28" />
                      )}
                      {account.type === 'usa' && (
                        <>
                          <UsaBankIcon className="relative mr-1 hidden w-6 text-slate-400/80 sm:inline-block" />{' '}
                          {account.alias}
                        </>
                      )}
                      {account.type === 'international' && (
                        <>
                          <GlobeAltIcon className="relative top-[-2px] mr-1 hidden w-6 text-slate-400/80 sm:inline-block" />{' '}
                          {account.alias}
                        </>
                      )}
                      {isInUse && (
                        <span
                          className={classNames(
                            'ml-2 text-slate-400',
                            account.type === 'payoneer' && 'relative top-1'
                          )}
                        >
                          (in use)
                        </span>
                      )}
                    </div>

                    <div className="flex shrink self-baseline">
                      <div
                        className={classNames(
                          account.type === 'payoneer' && 'invisible',
                          'w-24 text-left font-mono text-sm leading-8 text-slate-500'
                        )}
                      >
                        {account.type === 'payoneer'
                          ? ''
                          : `****${account.accountNumber.toString().slice(-4)}`}
                      </div>
                      <div className="px-2 leading-8">
                        <Tooltip content={account.status}>
                          {
                            {
                              Rejected: (
                                <ShieldExclamationIcon className="h-8 w-5 cursor-help text-red-500" />
                              ),
                              Approved: (
                                <ShieldCheckIcon className="h-8 w-5 cursor-help text-green-500" />
                              ),
                              'Waiting for approval': (
                                <ClockIcon className="h-8 w-5 cursor-help text-amber-500" />
                              ),
                            }[account.status]
                          }
                        </Tooltip>
                      </div>
                    </div>

                    <div className="self-center pl-2">
                      <Menu as="div" className="relative">
                        <Menu.Button className="block rounded-md p-2 outline-none hover:bg-slate-100 focus-visible:ring-2 aria-expanded:bg-slate-100 sm:p-1">
                          <span className="sr-only">Open account menu</span>
                          <EllipsisHorizontalIcon className="h-4 w-4 text-slate-500" />
                        </Menu.Button>

                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-1 min-w-max origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block w-full px-8 py-2 text-left text-lg text-gray-700 sm:px-4 sm:text-sm'
                                  )}
                                  type="button"
                                  onClick={() => {
                                    setSelectedAccount(account)
                                    setAction('details')
                                  }}
                                >
                                  View details
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block w-full px-8 py-2 text-left text-lg text-red-700 disabled:cursor-not-allowed disabled:text-gray-400  sm:px-4 sm:text-sm'
                                  )}
                                  onClick={() => {
                                    setSelectedAccount(account)
                                    setAction('delete')
                                  }}
                                  disabled={isInUse}
                                  type="button"
                                >
                                  Remove
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </li>
                )
              })}
          </ul>
        ) : (
          <div className="my-6 rounded-md bg-slate-50 pb-12 pt-8">
            <p className="text-center text-sm italic text-slate-500">
              You have no accounts yet
            </p>
            <div className="mt-6 text-center">
              <Link
                className="whitespace-nowrap rounded-md bg-sky-500 px-6 py-3 text-sm font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2"
                to="new"
              >
                <PlusIcon className="relative -top-[1px] -ml-2 mr-1 inline-block w-5" />{' '}
                Add Account
              </Link>
            </div>
          </div>
        )
      ) : null}

      <BaseDialog
        afterLeave={() => setSelectedAccount(null)}
        onClose={() => setAction(null)}
        isOpen={action === 'details'}
        title="Account details"
      >
        {selectedAccount && <AccountDetails account={selectedAccount} />}
      </BaseDialog>

      <BaseDialog
        noCloseButton
        afterLeave={() => setSelectedAccount(null)}
        onClose={() => setAction(null)}
        isOpen={action === 'delete'}
        title="Remove Account"
        size="sm"
      >
        <div className="mt-4">
          <p className="text-sm text-slate-500">
            Are you sure you that want to remove{' '}
            <strong className="text-slate-600">{selectedAccount?.alias}</strong>{' '}
            from your accounts?
          </p>
          <p className="mt-2 text-sm text-slate-500">
            This action cannot be undone.
          </p>

          <div className="mt-6 flex gap-4">
            <button
              className="rounded-md bg-red-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-red-400 ring-offset-2 hover:bg-red-600 focus-visible:ring-2"
              onClick={handleRemoveConfirmation}
            >
              Remove
            </button>
            <button
              className="rounded-md px-4 py-3 text-sm text-slate-500 underline decoration-slate-400 underline-offset-1 outline-none hover:text-slate-700 focus-visible:ring-2"
              onClick={() => setAction(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </BaseDialog>
    </div>
  )
}
