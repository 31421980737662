import { fromZodError } from 'zod-validation-error'
import { googleLogout } from '@react-oauth/google'
import { ZodSchema } from 'zod'

import { tokenStorageKey, userStorageKey } from './config'
import { GENERIC_ERROR_MESSAGE } from './api/common'
import { ApiDate, PageError } from './types'

export function classNames(
  ...classes: (string | false | null | undefined)[]
): string {
  return classes.filter(Boolean).join(' ')
}

export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'code',
})

export function validateResponse<Output>(
  schema: ZodSchema<Output>,
  errorMessage = GENERIC_ERROR_MESSAGE
) {
  return async function (response: Response): Promise<Output> {
    if (!response.ok) {
      const error: PageError = new Error(errorMessage)
      error.status = response.status
      try {
        error.info = await response.json()
      } catch (e) {
        error.info = { message: response.statusText }
      }
      throw error
    }
    const res = await response.json()
    const result = schema.safeParse(res)
    if (result.success) {
      return result.data
    }
    console.warn(
      fromZodError(result.error, { prefix: `(URL: ${response.url})` })
    )
    return res
  }
}

export function signOut(): void {
  googleLogout()
  // once logged out from Google, clear the browser storage
  window.localStorage.removeItem(tokenStorageKey)
  window.localStorage.removeItem(userStorageKey)
  // force a refresh to clear all of SWR cache
  window.location.reload()
}

export function checkAuth(response: Response): Response {
  if (response.status === 401) signOut()
  return response
}

export function dateFromIsoString(isoString: ApiDate): Date {
  /**
   * We need to append a time string to the date, otherwise the Date object
   * will be considered UTC and when formatting back to ISO we might end up
   * on the previous or following day.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#sect2
   */
  return new Date(isoString + 'T00:00:00')
}
