import * as React from 'react'

import { classNames } from '../utils'

/**
 * An 'indeterminate' loading bar that can be used as an animated border
 *
 * It handles an internal "active" state so that the animation always start
 * from the left and always end on the right even if the `isLoading` prop
 * changed to false before the bar fully reached the right hand side.
 */
export default function LoadingBar({ isLoading }: { isLoading: boolean }) {
  const [isActive, setIsActive] = React.useState(isLoading)

  React.useEffect(() => {
    // each time the prop changes to `true` set the internal active state
    if (isLoading) setIsActive(true)
  }, [isLoading])

  return (
    <div
      className={classNames(
        "relative h-0.5 w-full overflow-hidden bg-slate-200 after:absolute after:left-0 after:top-0 after:h-0.5 after:w-1/3 after:animate-loader after:bg-red-500 after:content-[''] after:[animation-fill-mode:forwards]",
        isActive ? 'after:block' : 'after:hidden'
      )}
      onAnimationIteration={() => {
        // each time the bar reaches the right-hand side, check the loading prop
        // and reset the internal state if it's falsy
        if (!isLoading) setIsActive(false)
      }}
    />
  )
}
