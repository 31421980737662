import { rest } from 'msw'

import { PaymentSetup } from '../types'
import { apiBaseUrl } from '../config'

const fakePaymentSetup: PaymentSetup = {
  fixed: { amount: 'all', account: 'Chase' },
  rest: 'BofA',
}

const getPaymentSetupHandler = rest.get<null, {}, PaymentSetup>(
  apiBaseUrl + '/last-payment-setup',
  (req, res, ctx) => {
    // return res(ctx.status(404), ctx.delay(1500))
    return res(ctx.status(200), ctx.json(fakePaymentSetup), ctx.delay(500))
  }
)

export const handlers = [getPaymentSetupHandler]
