import { checkAuth, validateResponse } from '../utils'
import { Team, teamSchema } from '../types'
import { apiBaseUrl, tokenStorageKey } from '../config'

export async function getTeam(): Promise<Team> {
  return fetch(apiBaseUrl + '/my-team', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(teamSchema))
}
