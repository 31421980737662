import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { userStorageKey } from '../config'

export default function useUser(options?: SWRConfiguration) {
  return useSWR<{ fullName: string; email: string; avatarUrl: string }, Error>(
    'user',
    function userFetcher() {
      /**
       * If we have a user stored locally, assume its token is valid.
       * Optionally, we could validate the stored token here and clear the storage
       * if it's not and force a new login.
       */
      const stored = localStorage.getItem(userStorageKey)
      return stored ? JSON.parse(stored) : null
    },
    options
  )
}
