import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getTimeOffSummary } from '../api/timeoffApi'
import { TimeOffSummary } from '../types'

export default function useTimeOffSummary(options?: SWRConfiguration) {
  return useSWR<TimeOffSummary[], Error>(
    'timeoff-summary',
    () =>
      getTimeOffSummary().then((periods) =>
        periods
          .map((period) => ({
            ...period,
            daysOff: (period.daysOff || []).sort(
              (dayA, dayB) => dayA.ndate - dayB.ndate
            ),
          }))
          .sort((periodA, periodB) => periodB.year - periodA.year)
      ),
    options
  )
}
