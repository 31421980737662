import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { Contract, PageError, contractSchema } from '../types'
import { apiBaseUrl, tokenStorageKey } from '../config'
import { checkAuth, validateResponse } from '../utils'

export default function useActiveContract(options?: SWRConfiguration) {
  return useSWR<Contract, PageError>(
    'active-contract',
    () =>
      fetch(apiBaseUrl + '/active-contract', {
        headers: { Authorization: window.localStorage[tokenStorageKey] },
      })
        .then(checkAuth)
        .then(validateResponse(contractSchema)),
    options
  )
}
