import { RegisterOptions } from 'react-hook-form'

import useAccounts from './useAccounts'

export default function useAccountAliasValidate(): RegisterOptions['validate'] {
  const { data } = useAccounts()
  return (value) =>
    data?.some(({ alias }) => alias === value)
      ? `An account called ${value} already exists`
      : true
}
