import { PaymentSetup, paymentSetupSchema } from '../types'
import { checkAuth, validateResponse } from '../utils'
import { GENERIC_ERROR_MESSAGE } from './common'
import { apiBaseUrl, tokenStorageKey } from '../config'

export async function getPaymentSetup(): Promise<PaymentSetup | null> {
  return fetch(apiBaseUrl + '/last-payment-setup', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then((res) => {
      // if payments are not setup yet, API returns a 404
      if (res.status === 404) return null
      return validateResponse(
        paymentSetupSchema,
        "It seems we couldn't retrieve your payment setup at this moment. Please, try again later."
      )(res)
    })
}

export async function updatePaymentSetup(
  body: PaymentSetup
): Promise<PaymentSetup> {
  return fetch(apiBaseUrl + '/payment-setups', {
    headers: {
      Authorization: window.localStorage[tokenStorageKey],
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then(checkAuth)
    .then((res) => {
      if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
      return res.json()
    })
}
