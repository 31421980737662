import {
  ExclaimationTriangleIcon,
  ExclaimationCircleIcon,
  CalendarDaysIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/outline'
import isWithinInterval from 'date-fns/isWithinInterval'
import startOfToday from 'date-fns/startOfToday'
import * as React from 'react'
import addYears from 'date-fns/addYears'
import subDays from 'date-fns/subDays'
import format from 'date-fns/format'

import useTimeOffSummary from '../hooks/useTimeOffSummary'
import { classNames } from '../utils'
import Spinner from '../components/Spinner'
import Tooltip from '../components/Tooltip'

export default function TimeOffHistoryPage() {
  const summary = useTimeOffSummary()

  return (
    <div className="max-w-2xl">
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>

      {summary.data && (
        <ol className="mt-6 space-y-3 lg:space-y-4">
          {summary.data.map((period, i) => {
            const isCurrentPeriod = isWithinInterval(startOfToday(), {
              start: period.start,
              end: period.end,
            })
            const previousPeriod = summary.data?.[i + 1]

            /**
             * Because of how the backend calculates the currently accrued days
             * the `end` property is populated with the current date during the
             * the first period, instead the actual period end.
             */
            const actualPeriodEnd =
              isCurrentPeriod && period.year === 1
                ? subDays(addYears(period.start, 1), 1)
                : period.end

            return (
              <li
                className="rounded-xl bg-slate-100 px-4 py-4"
                key={period.start.toString()}
              >
                <h2 className="-mx-4 flex items-baseline gap-1 border-b px-4 pb-2">
                  <div className="flex grow items-baseline gap-2">
                    <span className="whitespace-nowrap text-sm font-semibold text-slate-500">
                      Period {period.year}
                    </span>{' '}
                    {isCurrentPeriod && (
                      <span className="relative -top-px ml-1 rounded border border-green-600/50 px-1 text-[11px] font-semibold uppercase text-green-600">
                        current
                      </span>
                    )}
                  </div>
                  <CalendarDaysIcon className="relative -top-0.5 hidden w-5 self-center rounded-full text-slate-500 md:inline-block" />{' '}
                  <span className="text-sm font-semibold text-slate-500">
                    {format(period.start, 'PP')}
                    <ArrowRightIcon className="relative -top-px mx-1 inline-block h-5 w-5 origin-center scale-75 text-slate-500" />
                    {format(actualPeriodEnd, 'PP')}
                  </span>
                </h2>

                <dl className="mt-3 grid grid-cols-3 divide-x divide-slate-300">
                  <div className="flex flex-col justify-between py-1">
                    <dt className="px-1 text-center text-[11px] uppercase text-slate-500 [letter-spacing:0.5px]">
                      Accrued
                    </dt>
                    <dd className="px-1 text-center text-2xl text-green-600">
                      {period.accrued}
                    </dd>
                  </div>
                  {period.year > 1 && (
                    <div className="flex flex-col justify-between py-1">
                      <dt className="px-1 text-center text-[11px] uppercase text-slate-500 [letter-spacing:0.5px]">
                        Carried over
                      </dt>
                      <dd className="px-1 text-center text-2xl">
                        <span className="text-green-600">
                          {period.carriedOverFromLastYear > 0 && (
                            <span className="relative -top-0.5 text-xl">+</span>
                          )}
                          {period.carriedOverFromLastYear}
                        </span>
                        {previousPeriod &&
                          previousPeriod.totalInPeriod -
                            previousPeriod.consumed >
                            5 && (
                            <Tooltip
                              content={`${
                                previousPeriod.totalInPeriod -
                                previousPeriod.consumed
                              } unused PTO on previous period. You can carry over 5 days max, so you essentially lost ${
                                previousPeriod.totalInPeriod -
                                previousPeriod.consumed -
                                5
                              } days 😔`}
                            >
                              <ExclaimationCircleIcon className=" ml-0.5 inline-block h-5 w-5 origin-bottom scale-90 cursor-help text-slate-400" />
                            </Tooltip>
                          )}
                      </dd>
                    </div>
                  )}
                  <div className="flex flex-col justify-between py-1">
                    <dt className="px-1 text-center text-[11px] uppercase text-slate-500 [letter-spacing:0.5px]">
                      Consumed
                    </dt>
                    <dd
                      className={classNames(
                        'px-1 text-center text-2xl',
                        period.consumed > 0
                          ? 'text-orange-500'
                          : 'text-slate-400'
                      )}
                    >
                      {period.consumed}
                    </dd>
                  </div>
                </dl>

                {(period.daysOff || []).length > 0 ? (
                  <div className="mt-3 rounded-lg bg-white py-3 pl-3 pr-2">
                    <h2 className="text-xs font-semibold text-slate-500 md:text-sm">
                      Consumed PTO {isCurrentPeriod && 'so far'}
                    </h2>
                    <ol className="mt-2 list-decimal space-y-0.5 pl-4 text-xs text-slate-600 marker:text-slate-400 md:space-y-1 md:text-sm">
                      {(period.daysOff || []).map((pto) => {
                        return (
                          <li key={pto.ndate.toString()}>
                            {format(pto.ndate, 'EEE, PPP')}
                          </li>
                        )
                      })}
                    </ol>
                  </div>
                ) : (
                  <p className="pb-1 pt-6 text-center text-xs italic text-slate-600">
                    {isCurrentPeriod
                      ? 'No PTO days consumed yet'
                      : 'No PTO days consumed during this period'}
                  </p>
                )}

                {isCurrentPeriod && period.totalInPeriod - period.consumed > 5 && (
                  <div className="mt-4 rounded-md bg-orange-100 px-4 py-4 text-sm text-orange-800 shadow">
                    <ExclaimationTriangleIcon className="relative -top-0.5 -ml-0.5 inline-block h-5 w-5 align-middle" />{' '}
                    <strong className="font-semibold">
                      Heads up! you have{' '}
                      {period.totalInPeriod - period.consumed} PTO days
                      available
                    </strong>
                    <div className="mt-2">
                      <p>
                        A maximum of 5 unused PTO days can be carried over onto
                        the next period.
                      </p>
                      <p className="mt-2">
                        Make sure you use{' '}
                        <strong>
                          at least {period.totalInPeriod - period.consumed - 5}{' '}
                          {period.totalInPeriod - period.consumed - 5 === 1
                            ? 'day'
                            : 'days'}
                        </strong>{' '}
                        before the end of the current period (
                        {format(actualPeriodEnd, 'PPP')}) to avoid losing{' '}
                        {period.totalInPeriod - period.consumed - 5 === 1
                          ? 'it'
                          : 'them'}
                        .
                      </p>
                    </div>
                  </div>
                )}
              </li>
            )
          })}
        </ol>
      )}

      {!summary.data && !summary.error && (
        <div className="my-4 text-2xl">
          <Spinner className="mx-auto my-12" />
        </div>
      )}

      {summary.error && !summary.data && (
        <div className="my-3 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-0.5 inline-block h-4 w-4 align-middle" />{' '}
          {summary.error.message}
        </div>
      )}
    </div>
  )
}
