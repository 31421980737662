import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'

export default function ErrorMessage({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div className="my-6 max-w-prose px-8 py-12 text-center">
      <ExclaimationCircleIcon className="mx-auto mb-3 w-16 text-red-500" />
      <div className="text-lg text-slate-500">{children}</div>
    </div>
  )
}
