import { motion, AnimatePresence } from 'framer-motion'
import * as React from 'react'

import type { Toast, Options } from './Toast'
import { createToast } from './Toast'

import { classNames } from '../utils'

const ToastsContext = React.createContext<{
  /**
   * Adds a toast to the stack. You can pass an `options` object as a
   * second argument to customize its appearance and behavior.
   *
   * The toast `id` is returned in case you need to programatically close
   * the toast from somewhere else using `removeToast(id)`.
   */
  addToast(message: Toast['message'], options?: Options): Toast['id']
  /**
   * Removes a toast from the stack by id. An automattic `id` is returned
   * from the `addToast()` method or you can provide a custom one when
   * triggering the toast.
   */
  removeToast(toastId: Toast['id']): void
}>({
  addToast: () => '',
  removeToast: () => {},
})

/**
 * Returns functions to add and remove toasts.
 */
export function useToasts() {
  return React.useContext(ToastsContext)
}

/**
 * Enter and exit animation parameters.
 */
const initialParams = { opacity: 0, y: 28, scale: 0.8 }
const animateParams = { opacity: 1, y: 0, scale: 1 }
const exitParams = { opacity: 0, scale: 0.8, transition: { duration: 0.2 } }

interface Props {
  children: React.ReactNode
}

/**
 * A notifications system with a hooks API to allow triggering toasts in an
 * imperative way from inside React components.
 *
 * Features:
 *
 *   - Default markup with `info`, `warning` and `danger` variants
 *   - Support for custom markup
 *   - Auto remove, manual remove or permanent
 *   - Optional spinner icon
 *   - Optional custom action
 */
export const ToastsProvider = ({ children }: Props) => {
  const [toasts, setToasts] = React.useState<Toast[]>([])

  const removeToast = React.useCallback((toastId: Toast['id']) => {
    setToasts((current) => {
      return current.filter(({ id }) => id !== toastId)
    })
  }, [])

  const addToast = React.useCallback(
    (message: Toast['message'], options?: Options) => {
      const newToast = createToast(message, options)
      setToasts((current) => [...current, newToast])
      if (newToast.autoCloseDelay > 0) {
        setTimeout(() => removeToast(newToast.id), newToast.autoCloseDelay)
      }
      return newToast.id
    },
    [removeToast]
  )

  // Memoize context to avoid re-renders
  const value = React.useMemo(() => {
    return { removeToast, addToast }
  }, [removeToast, addToast])

  return (
    <ToastsContext.Provider value={value}>
      {children}

      <ol className="pointer-events-none fixed bottom-7 left-3 right-3 z-50 flex flex-col-reverse items-center gap-3 sm:left-7 sm:right-7">
        <AnimatePresence initial={false}>
          {toasts.map((toast) => (
            <motion.li
              className={classNames(
                'pointer-events-auto',
                toast.align === 'center' && 'self-center',
                toast.align === 'right' && 'self-end',
                toast.align === 'left' && 'self-start'
              )}
              initial={initialParams}
              animate={animateParams}
              exit={exitParams}
              layout
              key={toast.id}
            >
              {React.createElement(toast.component, {
                key: toast.id,
                ...toast,
                remove: () => removeToast(toast.id),
              })}
            </motion.li>
          ))}
        </AnimatePresence>
      </ol>
    </ToastsContext.Provider>
  )
}
