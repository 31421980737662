import { rest } from 'msw'

import { TaxAdvisorSessionRequest } from '../types'
import { apiBaseUrl } from '../config'

const requestTaxAdvisorSessionHandler = rest.post<
  TaxAdvisorSessionRequest,
  {},
  TaxAdvisorSessionRequest
>(apiBaseUrl + '/tax-advisor-request', (req, res, ctx) => {
  // send message as "error" to mock an error response
  if (req.body.message === 'error') {
    return res(ctx.status(400), ctx.delay(200))
  }
  return res(ctx.status(200), ctx.json({}), ctx.delay(600))
})

export const handlers = [requestTaxAdvisorSessionHandler]
