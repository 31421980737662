import { rest } from 'msw'

import { apiBaseUrl } from '../config'
import { Payment } from '../types'

const fakePayments: Payment[] = [
  {
    _id: '632957286d5599d5fe1328a1',
    status: 'done',
    amount: 5000,
    date: Date.now() - 1000 * 60 * 60 * 24 * 30,
    discounts: [],
  },
  {
    _id: '632957fb6d5599d5fe1340c1',
    status: 'done',
    amount: 4960,
    date: Date.now() - 1000 * 60 * 60 * 24 * 60,
    discounts: [
      { amount: 20, reason: 'PTO on 09-05-2022' },
      { amount: 20, reason: 'PTO on 09-06-2022' },
    ],
  },
]

const fakeScheduledNextPayment: Payment = {
  _id: '632957fb6d5599d5fe1340c1',
  status: 'Scheduled',
  amount: 4960,
  date: Date.now() + 1000 * 60 * 60 * 24 * 7,
  discounts: [
    { amount: 20, reason: 'PTO on 09-05-2022' },
    { amount: 20, reason: 'PTO on 09-06-2022' },
  ],
}

const fakeLastPayment: Payment = {
  _id: '632957fb6d5599d5fe1340c1',
  status: 'done',
  amount: 5500,
  date: Date.now() - 1000 * 60 * 60 * 24 * 7,
  discounts: [
    { amount: 20, reason: 'PTO on 09-05-2022' },
    { amount: 20, reason: 'PTO on 09-06-2022' },
  ],
}

const getNextPaymentHandler = rest.get<null, {}, Payment>(
  apiBaseUrl + '/next-payment',
  (req, res, ctx) => {
    const scheduled = true
    // return res(ctx.status(404), ctx.delay(1500))
    return scheduled
      ? res(ctx.status(200), ctx.json(fakeScheduledNextPayment), ctx.delay(500))
      : res(ctx.status(200), ctx.json(fakeLastPayment), ctx.delay(500))
  }
)

const getPaymentsHandler = rest.get<null, {}, Payment[]>(
  apiBaseUrl + '/payments',
  (req, res, ctx) => {
    // return res(ctx.status(404), ctx.delay(1500))
    return res(ctx.status(200), ctx.json(fakePayments), ctx.delay(500))
  }
)

export const handlers = [getNextPaymentHandler, getPaymentsHandler]
