import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'

import { southteamsEmailAddress } from '../config'
import { signOut } from '../utils'
import useUser from '../hooks/useUser'

interface Props {}

export default function NoContractError(props: Props) {
  const { data } = useUser()

  const url = new URL(`mailto:${southteamsEmailAddress}`)
  url.searchParams.set('subject', 'Error on me.southteams.com portal')
  url.searchParams.set(
    'body',
    `Hi,
I'm having issues with my user on me.southteams.com portal. It doesn't have an active contract.
Can you take a look?

user: ${data?.fullName} (${data?.email})

Thanks.`
  )

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white/90">
      <div className=" max-w-md px-4 pb-20">
        <ExclaimationCircleIcon className="mx-auto mb-2 w-16 text-red-500" />
        <h1 className="text-center text-2xl text-slate-600">
          No Active Contract
        </h1>

        <p className="mt-4 text-center text-base text-slate-500">
          The user <strong>{data?.email}</strong> does not have an active
          contract, which is required to use this site.
        </p>
        <p className="mt-2 text-center text-base text-slate-500">
          Contact us at{' '}
          <a
            className="text-red-500 underline underline-offset-2 outline-2 outline-red-300 hover:text-red-700 focus-visible:outline focus-visible:outline-offset-2 focus-visible:ring-0"
            target="_blank"
            href={url.toString()}
            rel="noreferrer"
          >
            {southteamsEmailAddress}
          </a>
          .
        </p>

        <div className="mt-6 flex justify-center gap-4">
          <button
            className="block rounded-md px-4 py-2 text-sm text-slate-400  underline underline-offset-2 outline-none ring-slate-400 ring-offset-2 hover:text-slate-600 focus-visible:ring-2"
            onClick={signOut}
            type="button"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}
