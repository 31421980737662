import { Link } from 'react-router-dom'
import * as React from 'react'

export default function MocksIndex() {
  return (
    <div className="container mx-auto my-20 max-w-xl px-4">
      <h1 className="mb-4 text-3xl">Mocked states</h1>
      <p className="text-sm text-slate-600">
        Quick access to every possible state using a mocked Service Worker API.
      </p>
      <p className="mt-2 text-sm text-slate-600">
        This URL returns a 404 page when mocks are disabled via environment
        variables.
      </p>

      <nav>
        <h2 className="mt-12 text-xs font-semibold uppercase text-slate-500">
          Misc
        </h2>
        <ul className="mt-4 list-disc space-y-3 pl-5 marker:text-slate-400">
          <li>
            <Link
              className="text-orange-600 underline underline-offset-2 hover:text-orange-700"
              to="/login"
            >
              Login page
            </Link>
          </li>
          <li>
            <Link
              className="text-orange-600 underline underline-offset-2 hover:text-orange-700"
              to="/not-found"
            >
              404 page
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
