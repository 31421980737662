import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getTimeOffBalance } from '../api/timeoffApi'
import { TimeOffBalance } from '../types'

export default function useTimeOffBalance(options?: SWRConfiguration) {
  return useSWR<TimeOffBalance, Error>(
    'pto/balance',
    getTimeOffBalance,
    options
  )
}
