import { z } from 'zod'

import { checkAuth, validateResponse } from '../utils'
import { Document, documentSchema } from '../types'
import { apiBaseUrl, tokenStorageKey } from '../config'

export async function getDocuments(): Promise<Document[]> {
  return fetch(apiBaseUrl + '/docs', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(documentSchema)))
}
