import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getPayrollTransactions } from '../api/paymentsApi'
import { PayrollTransaction } from '../types'

export default function usePayrollTransactions(options?: SWRConfiguration) {
  return useSWR<PayrollTransaction[], Error>(
    'transactions',
    getPayrollTransactions,
    options
  )
}
