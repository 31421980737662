import {
  ClipboardDocumentIcon,
  ExclaimationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'

import { southteamsEmailAddress } from '../config'
import { useToasts } from './ToastsProvider'
import { PageError } from '../types'
import { signOut } from '../utils'
import useUser from '../hooks/useUser'
import Tooltip from './Tooltip'

interface Props {
  onDismiss(): void
  error: PageError
}

export default function ErrorOverlay(props: Props) {
  const { addToast } = useToasts()
  const { data } = useUser()

  const url = new URL(`mailto:${southteamsEmailAddress}`)
  url.searchParams.set('subject', 'Error on me.southteams.com portal')
  if (props.error.info?.requestId) {
    url.searchParams.set(
      'body',
      `Hi,
I'm having issues with my user on me.southteams.com portal.
Can you take a look?

user: ${data?.fullName} (${data?.email})
requestId: ${props.error.info.requestId}

Thanks.`
    )
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white/90">
      <div className=" max-w-md px-4 pb-20">
        <ExclaimationCircleIcon className="mx-auto mb-2 w-16 text-red-500" />
        <h1 className="text-center text-2xl text-slate-600">
          {props.error.status} - {props.error.info?.message || 'Server Error'}
        </h1>

        <p className="mt-6 text-center text-base text-slate-500">
          Contact us at{' '}
          <a
            className="text-red-500 underline underline-offset-2 outline-2 outline-red-300 hover:text-red-700 focus-visible:outline focus-visible:outline-offset-2 focus-visible:ring-0"
            target="_blank"
            href={url.toString()}
            rel="noreferrer"
          >
            {southteamsEmailAddress}
          </a>
          {props.error.info?.requestId ? ' sharing the following code' : ''}
        </p>

        {props.error.info?.requestId && (
          <div className="mt-4 flex justify-center ">
            <div className="flex items-center gap-2  rounded-md bg-slate-200 px-4 py-3 text-center ">
              <span className="whitespace-nowrap font-mono text-slate-600">
                {props.error.info.requestId}
              </span>
              <Tooltip content="Copy to clipboard">
                <button
                  aria-label="Copy to clipboard"
                  className="-my-1 -mr-2 block rounded-full p-2 text-slate-400 underline  underline-offset-2 outline-none ring-slate-400 hover:text-slate-600 focus-visible:bg-slate-100 focus-visible:ring-2"
                  type="button"
                  onClick={() => {
                    if (!props.error.info?.requestId) return
                    navigator.clipboard
                      .writeText(props.error.info.requestId)
                      .then(() => addToast('Request ID copied to clipboard'))
                  }}
                >
                  <ClipboardDocumentIcon className="w-5" />
                </button>
              </Tooltip>
            </div>
          </div>
        )}

        <div className="mt-6 flex justify-center gap-4">
          <button
            className="block rounded-md px-4 py-2 text-sm text-slate-400  underline underline-offset-2 outline-none ring-slate-400 ring-offset-2 hover:text-slate-600 focus-visible:ring-2"
            onClick={signOut}
            type="button"
          >
            Logout
          </button>

          <button
            className="absolute right-4 top-3.5 block rounded-full p-2 text-slate-500  underline underline-offset-2 outline-none ring-slate-400 hover:text-slate-600 focus-visible:ring-2"
            onClick={props.onDismiss}
            type="button"
          >
            <XMarkIcon className="w-5" />
          </button>
        </div>
      </div>
    </div>
  )
}
