import { motion, AnimatePresence } from 'framer-motion'
import type { Placement } from '@floating-ui/react-dom-interactions'
import * as React from 'react'
import {
  useInteractions,
  useFloating,
  autoUpdate,
  useDismiss,
  useFocus,
  useHover,
  useRole,
  offset,
  shift,
  arrow,
  flip,
} from '@floating-ui/react-dom-interactions'

// Animation parameters
const initialParams = { opacity: 0, scale: 0.8 }
const animateParams = { opacity: 1, scale: 1, transition: { duration: 0.1 } }
const exitParams = { opacity: 0, scale: 0.8, transition: { duration: 0.1 } }

// Helper for arrow placement
const staticSide = {
  top: 'bottom',
  'top-end': 'bottom',
  'top-start': 'bottom',
  right: 'left',
  'right-start': 'left',
  'right-end': 'left',
  bottom: 'top',
  'bottom-start': 'top',
  'bottom-end': 'top',
  left: 'right',
  'left-start': 'right',
  'left-end': 'right',
}

interface Props {
  placement?: Placement
  children: JSX.Element
  content: React.ReactNode
}

export default function Tooltip({
  placement = 'top',
  children,
  content,
}: Props) {
  const arrowRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const {
    middlewareData,
    placement: currentPlacement,
    reference,
    floating,
    strategy,
    context,
    x,
    y,
  } = useFloating({
    whileElementsMounted: autoUpdate,
    onOpenChange: setOpen,
    middleware: [
      offset(8),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef, padding: 10 }),
    ],
    placement,
    open,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { delay: { open: 40, close: 120 } }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  return (
    <>
      {React.cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props })
      )}
      <AnimatePresence>
        {open && (
          <motion.div
            initial={initialParams}
            animate={animateParams}
            exit={exitParams}
            {...getFloatingProps({
              className:
                'max-w-[260px] rounded-md bg-slate-600 px-3 py-1.5 text-sm text-slate-50 shadow-lg z-20 whitespace-normal',
              style: { position: strategy, top: y ?? 0, left: x ?? 0 },
              ref: floating,
            })}
          >
            {content}
            <div
              className="absolute h-2 w-2 rotate-45 bg-inherit"
              ref={arrowRef}
              style={{
                left:
                  typeof middlewareData.arrow?.x === 'number'
                    ? middlewareData.arrow.x
                    : '',
                top:
                  typeof middlewareData.arrow?.y === 'number'
                    ? middlewareData.arrow.y
                    : '',
                right: '',
                bottom: '',
                [staticSide[currentPlacement]]: '-4px',
              }}
            ></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
