import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'

import DownloadFileLink from '../components/DownloadFileLink'
import useDocuments from '../hooks/useDocuments'
import Spinner from '../components/Spinner'

export default function DocumentsPage() {
  const { data, error } = useDocuments()

  const isLoading = !data && !error

  return (
    <div className="max-w-prose">
      <h1 className="text-3xl">Documents</h1>

      <p className="mt-2 text-slate-600">
        Lorem ipsum dolor sit amet, consectetur adipisicing sint occaecat dent,
        sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>

      {data &&
        (data.length ? (
          <ul className="mt-6 space-y-4">
            {data.map((doc) => {
              return (
                <li key={doc.link}>
                  <DownloadFileLink fileName={doc.title} url={doc.link}>
                    {doc.title}
                  </DownloadFileLink>
                </li>
              )
            })}
          </ul>
        ) : (
          <p className="mt-6 italic text-slate-400">No documents</p>
        ))}

      {isLoading && <Spinner className="mx-auto my-12" />}

      {error && !data && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">Oops...</strong>
          <p className="mt-2">{error.message}</p>
        </div>
      )}
    </div>
  )
}
