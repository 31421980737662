import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getTimeOffRequests } from '../api/timeoffApi'
import { TimeOffRequest } from '../types'

export default function useTimeOffRequests(options?: SWRConfiguration) {
  return useSWR<TimeOffRequest[], Error>(
    'timeoff-requests',
    () =>
      getTimeOffRequests().then((d) =>
        d.sort((a, b) => (a.createdOn > b.createdOn ? -1 : 1))
      ),
    options
  )
}
