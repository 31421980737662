import { validateResponse } from '../utils'
import { Auth, authSchema } from '../types'
import { authApiBaseUrl } from '../config'

export async function getAccessToken(credential: string): Promise<Auth> {
  return fetch(authApiBaseUrl + '/v1/auth/token', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_token: credential }),
  }).then((res) => {
    if (res.status === 401) {
      throw new Error(
        'The user does not exist! If this seems wrong, contact us.'
      )
    }
    return validateResponse(authSchema)(res)
  })
}
