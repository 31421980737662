import { getTimezoneOffset } from 'date-fns-tz'
import isMongoId from 'validator/es/lib/isMongoId'
import { z } from 'zod'

const timestampSchema = z.number().int().min(0)

export const googleCredentialSchema = z.object({
  picture: z.string().url(),
  email: z.string().email(),
  name: z.string(),
})

export type GoogleCredential = z.infer<typeof googleCredentialSchema>

export const authSchema = z.object({
  token: z.string(),
})

export type Auth = z.infer<typeof authSchema>

export const profileSchema = z.object({
  cid: z.string().optional(),
  name: z.string().optional(),
  linkedin: z.string().url().optional(),
  email: z.string().email().optional(),
  southEmail: z.string().email().optional(),
  whatsapp: z.string().optional(),
  address: z.string().optional(),
  salary: z.number().optional(),
  birthdate: timestampSchema.optional(),
  startingDate: timestampSchema.optional(),
  enableTaxAdvisorBenefit: z.boolean().optional(),
})

export type Profile = z.infer<typeof profileSchema>

export const payoneerLinkSchema = z.object({
  _id: z.string(),
  url: z.string().url(),
  status: z.enum(['used', 'unused']),
})

export type PayoneerLink = z.infer<typeof payoneerLinkSchema>

export const commonAccountSchema = z.object({
  _id: z.string().refine(isMongoId),
  alias: z.string(),
  status: z.enum(['Waiting for approval', 'Approved', 'Rejected']),
  createdOn: timestampSchema,
  lastUpdateOn: timestampSchema,
})

export const accountSchema = z.discriminatedUnion('type', [
  commonAccountSchema.extend({
    type: z.literal('payoneer'),
  }),
  commonAccountSchema.extend({
    type: z.literal('international'),
    accountCurrency: z.string().optional(),
    accountCountry: z.string().optional(),
    bankSwift: z.string().optional(),
    bankName: z.string().optional(),
    bankAddress: z.string().optional(),
    beneficiary: z.string(),
    address: z.string().optional(),
    accountNumber: z.union([z.string(), z.number()]),
  }),
  commonAccountSchema.extend({
    type: z.literal('usa'),
    routingNumber: z.string().optional(),
    bankName: z.string().optional(),
    bankAddress: z.string().optional(),
    beneficiary: z.string(),
    address: z.string().optional(),
    accountNumber: z.union([z.string(), z.number()]),
  }),
])

export type Account = z.infer<typeof accountSchema>

export const contractSchema = z.object({
  _id: z.string().refine(isMongoId),
  candidateId: z.string().refine(isMongoId),
  active: z.boolean(),
  enableTaxAdvisorBenefit: z.boolean().optional(),
  ptoAccruedAutomaticallyAfterFirstYear: z.number().positive(),
  ptoAccruedPerMonthDuringFirstYear: z.number().positive(),
  ptoCarryOverLimit: z.number().positive(),
  startingDate: z.number().positive(),
})

export type Contract = z.infer<typeof contractSchema>

export const paymentSetupSchema = z.union([
  z.object({
    fixed: z.object({
      account: commonAccountSchema.shape._id,
      amount: z.literal('all'),
    }),
  }),
  z.object({
    fixed: z.object({
      account: commonAccountSchema.shape._id,
      amount: z.number(),
    }),
    rest: commonAccountSchema.shape._id,
  }),
])

export type PaymentSetup = z.infer<typeof paymentSetupSchema>

export const paymentDiscountSchema = z.object({
  amount: z.number().positive(),
  reason: z.string(),
})

export type PaymentDiscount = z.infer<typeof paymentDiscountSchema>

export const paymentSchema = z.object({
  _id: z.string().refine(isMongoId),
  status: z.enum(['Scheduled', 'done']),
  amount: z.number(),
  date: timestampSchema,
  discounts: z.array(paymentDiscountSchema).optional(),
})

export type Payment = z.infer<typeof paymentSchema>

export const paymentsBalanceSchema = z.object({
  balance: z.number(),
})

export type PaymentsBalance = z.infer<typeof paymentsBalanceSchema>

export const payrollTransactionStatusSchema = z.enum([
  'Draft',
  'Dismissed',
  'Approved',
  'Completed',
])

export type PayrollTransactionStatus = z.infer<
  typeof payrollTransactionStatusSchema
>

export const payrollTransactionSchema = z.object({
  _id: z.string().refine(isMongoId),
  type: z.enum(['credit', 'discount', 'debit']),
  candidateId: z.string().refine(isMongoId),
  dealId: z.string().refine(isMongoId).optional(),
  customerId: z.string().refine(isMongoId).optional(),
  payrollId: z.string().refine(isMongoId),
  amount: z.number(),
  description: z.string(),
  status: payrollTransactionStatusSchema,
  generatedByPayroll: z.boolean().optional(),
  createdOn: timestampSchema,
  createdBy: z.string().refine(isMongoId),
  completedOn: timestampSchema.optional(),
  completedBy: z.string().refine(isMongoId).optional(),
})

export type PayrollTransaction = z.infer<typeof payrollTransactionSchema>

export const documentSchema = z.object({
  title: z.string(),
  link: z.string().url(),
})

export type Document = z.infer<typeof documentSchema>

export const teamMemberSchema = z.object({
  name: z.string(),
  position: z.string().optional(),
  southEmail: z.string().email().or(z.null()).optional(),
  picture: z.string().url().optional(),
})

export type TeamMember = z.infer<typeof teamMemberSchema>

export const teamSchema = z.object({
  name: z.string(),
  members: z.array(teamMemberSchema),
  slack: z.string().url(),
})

export type Team = z.infer<typeof teamSchema>

export const timeOffBalanceSchema = z.object({
  available: z.number(),
  renewalDate: z.coerce.date(),
  accrued: z.number(),
  consumed: z.number(),
  carriedOverFromLastYear: z.number(),
})

export type TimeOffBalance = z.infer<typeof timeOffBalanceSchema>

export const apiDateSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/)

export type ApiDate = z.infer<typeof apiDateSchema>

export const timeOffRequestPreviewSchema = z.object({
  date: apiDateSchema,
  period: z.number().positive(),
  coveredByPtoPolicy: z.boolean(),
  dayValue: z.number().optional(),
})

export type TimeOffRequestPreview = z.infer<typeof timeOffRequestPreviewSchema>

export const timeOffStatusSchema = z.enum([
  'Waiting for approval',
  'Approved',
  'Canceled',
  'Rejected',
])

export type TimeOffStatus = z.infer<typeof timeOffStatusSchema>

export const timeOffRequestSchema = z.object({
  _id: z.string().refine(isMongoId),
  dates: z.array(apiDateSchema),
  status: timeOffStatusSchema,
  comments: z.string().optional(),
  createdOn: timestampSchema,
})

export type TimeOffRequest = z.infer<typeof timeOffRequestSchema>

export const timeOffSummarySchema = z.object({
  year: z.number().positive(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  accrued: z.number().min(0),
  consumed: z.number().min(0),
  carriedOverFromLastYear: z.number().min(0),
  totalInPeriod: z.number().min(0),
  balance: z.number(),
  daysOff: z
    .array(
      z.object({
        ndate: timestampSchema,
        status: timeOffStatusSchema,
      })
    )
    .optional(),
})

export type TimeOffSummary = z.infer<typeof timeOffSummarySchema>

export const timeOffTransactionSchema = z.object({
  _id: z.string().refine(isMongoId),
  amount: z.number().positive(),
  contractId: z.string().refine(isMongoId),
  timeOffRequestId: timeOffRequestSchema.shape._id.optional(),
  detail: z.string().optional(),
  type: z.enum(['credit', 'debit']),
  employeeBought: z.boolean().optional(),
  dayValue: z.number().optional(),
  status: timeOffStatusSchema,
  // Transaction dates are coded as 00:00 UTC-0 timestamps, so they could compute
  // as the previous or following day in different timezones. We need to get rid
  // of these offsets before anything else.
  date: timestampSchema.transform(
    (value) =>
      value -
      getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, value)
  ),
})

export type TimeOffTransaction = z.infer<typeof timeOffTransactionSchema>

export const holidaySchema = z.object({
  _id: z.string().refine(isMongoId),
  date: apiDateSchema,
  ndate: z.number().positive().optional(),
  name: z.string(),
  year: z.number().positive().min(2010),
})

export type Holiday = z.infer<typeof holidaySchema>

export type TaxAdvisorSessionRequest = {
  message?: string
}

export const candidateReferralSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  whatsapp: z.string().optional(),
  linkedin: z.string().url(),
  resume: z.string().url(),
  summary: z.string(),
  comments: z.string(),
})

export type CandidateReferral = z.infer<typeof candidateReferralSchema>

export type PageError = Error & {
  info?: { message: string; requestId?: string }
  status?: number
}
