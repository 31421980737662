import { z } from 'zod'

import {
  payrollTransactionSchema,
  paymentsBalanceSchema,
  PayrollTransaction,
  PaymentsBalance,
  paymentSchema,
  Payment,
} from '../types'
import { apiBaseUrl, tokenStorageKey } from '../config'
import { checkAuth, validateResponse } from '../utils'

export async function getNextPayment(): Promise<Omit<Payment, '_id'>> {
  return fetch(apiBaseUrl + '/next-payment', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(paymentSchema.omit({ _id: true })))
}

export async function getPayments(): Promise<Payment[]> {
  return fetch(apiBaseUrl + '/payments', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(paymentSchema)))
}

export async function getBalance(): Promise<PaymentsBalance> {
  return fetch(apiBaseUrl + '/balance', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(paymentsBalanceSchema))
}

export async function getPayrollTransactions(): Promise<PayrollTransaction[]> {
  return fetch(apiBaseUrl + '/transactions', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(payrollTransactionSchema)))
}
