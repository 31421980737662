import * as React from 'react'

export default function InvoicingPage() {
  return (
    <div className="max-w-2xl">
      <p className="text-slate-500">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <p className="mt-8 rounded-xl bg-slate-100 py-24 text-center italic text-slate-500">
        How to create an invoice tutorial + invoicing details
      </p>
    </div>
  )
}
