import type { NavLinkProps } from 'react-router-dom'
import { NavLink, Outlet } from 'react-router-dom'
import * as React from 'react'

import { classNames } from '../utils'

function Tab({
  children,
  end,
  to,
}: {
  children: React.ReactNode
  end?: NavLinkProps['end']
  to: NavLinkProps['to']
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames(
          isActive
            ? 'bg-slate-100 text-slate-800'
            : 'text-slate-500 hover:text-sky-600',
          'my-1 -ml-3 block whitespace-nowrap rounded-md px-3 py-2 text-sm font-semibold outline-none focus-visible:ring-2'
        )
      }
      end={end}
      to={to}
    >
      {children}
    </NavLink>
  )
}

export default function TimeOffPage() {
  return (
    <div className="pb-32">
      <h1 className="text-3xl">Time-off</h1>

      <nav className="my-4">
        <ul className="flex gap-8">
          <li>
            <Tab end to="">
              Overview
            </Tab>
          </li>
          <li>
            <Tab to="request">Request PTO</Tab>
          </li>
          <li>
            <Tab to="history">History</Tab>
          </li>
          <li>
            <Tab to="holidays">Holidays</Tab>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  )
}
