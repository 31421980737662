import { z } from 'zod'

import { apiBaseUrl, tokenStorageKey } from '../config'
import { checkAuth, validateResponse } from '../utils'
import {
  Account,
  PayoneerLink,
  accountSchema,
  payoneerLinkSchema,
} from '../types'

export async function getPayoneerLink(): Promise<PayoneerLink> {
  return fetch(apiBaseUrl + '/payoneer/link', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(
      validateResponse(
        payoneerLinkSchema,
        'We are unable to load your Payoneer link at the moment.'
      )
    )
}

export async function getAccounts(): Promise<Account[]> {
  return fetch(apiBaseUrl + '/accounts', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(
      validateResponse(
        z.array(accountSchema),
        'We are unable to load your accounts at the moment.'
      )
    )
}

export async function createAccount(
  body: Omit<Account, '_id'>
): Promise<Account> {
  return fetch(apiBaseUrl + '/accounts', {
    method: 'POST',
    headers: {
      Authorization: window.localStorage[tokenStorageKey],
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then(checkAuth)
    .then(async (res) => {
      if (!res.ok) throw new Error((await res.json()).message)
      return res.json()
    })
}

export async function removeAccount(id: Account['_id']): Promise<{}> {
  return fetch(apiBaseUrl + '/accounts/' + id, {
    method: 'DELETE',
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(async (res) => {
      if (!res.ok) throw new Error((await res.json()).message)
      return res.json()
    })
}
