import {
  ArrowTopRightOnSquareIcon,
  ExclaimationTriangleIcon,
  ExclaimationCircleIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'

import { southteamsEmailAddress } from '../config'
import usePayoneerLink from '../hooks/usePayoneerLink'
import { ENV_VARS } from '../env'
import Spinner from './Spinner'

export default function PayoneerForm() {
  const payoneerLink = usePayoneerLink()

  return (
    <div>
      <hr className="my-8" />

      {payoneerLink.data &&
        (payoneerLink.data.url ? (
          <>
            <p className="max-w-prose text-base  text-slate-500">
              Follow these steps to link a Payoneer account with us:
            </p>

            <ol className="mt-8 max-w-prose list-inside list-decimal space-y-8 text-slate-500">
              <li>
                Create a{' '}
                <a
                  className="text-base text-sky-600 underline underline-offset-1 outline-none hover:text-sky-700 focus-visible:ring-2"
                  target="_blank"
                  href="https://www.payoneer.com/accounts/"
                  rel="noreferrer"
                >
                  Payoneer
                  <ArrowTopRightOnSquareIcon className="relative top-[-1.5px] ml-1 inline w-4" />
                </a>{' '}
                account if you haven&apos;t already
              </li>
              <li>
                Follow this link to{' '}
                <a
                  className="text-base text-sky-600 underline underline-offset-1 outline-none hover:text-sky-700 focus-visible:ring-2"
                  href={payoneerLink.data.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  add Southteams as a funding source
                  <ArrowTopRightOnSquareIcon className="relative top-[-1.5px] ml-1 inline w-4" />
                </a>
              </li>
              <li>
                Wait for a <strong>confirmation e-mail</strong> on the account
                registered on Payoneer
                <img
                  src={ENV_VARS.PUBLIC_URL + '/images/payoneer-email.jpg'}
                  width={752 / 1.5}
                  height={487 / 1.5}
                  className="mx-auto my-4 block rounded-md shadow-lg"
                  alt="Southteams"
                />
              </li>
              <li>Once received, click the button below.</li>
            </ol>
          </>
        ) : (
          <div className="mt-6 max-w-prose rounded-md bg-amber-100 px-4 py-4 text-sm text-orange-800">
            <ExclaimationTriangleIcon className="-ml-0.5 mr-1 inline-block h-5 w-5 align-middle" />
            <strong className="font-semibold">
              Payoneer link not found in your profile
            </strong>
            <p className="mt-2">
              Contact us at{' '}
              <a
                className="text-sky-600 underline underline-offset-2 outline-none hover:text-sky-700 focus-visible:ring-2"
                target="_blank"
                href={`mailto:${southteamsEmailAddress}`}
                rel="noreferrer"
              >
                {southteamsEmailAddress}
              </a>{' '}
              so that we can take care of it.
            </p>
          </div>
        ))}

      {!payoneerLink.data && !payoneerLink.error && (
        <Spinner className="mx-auto my-12" />
      )}

      {payoneerLink.error && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">Oops...</strong>
          <p className="mt-2">{payoneerLink.error.message}</p>
        </div>
      )}
    </div>
  )
}
