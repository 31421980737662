import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getTimeOffTransactions } from '../api/timeoffApi'
import { TimeOffTransaction } from '../types'

export default function useTimeOffTransactions(options?: SWRConfiguration) {
  return useSWR<TimeOffTransaction[], Error>(
    'timeoff-transactions',
    getTimeOffTransactions,
    options
  )
}
