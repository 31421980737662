import {
  ExclaimationTriangleIcon,
  ExclaimationCircleIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import * as React from 'react'
import format from 'date-fns/format'

import { classNames, currencyFormat, dateFromIsoString } from '../utils'
import BaseDialog, { Props as BaseDialogProps } from './BaseDialog'
import useTimeOffRequestPreview from '../hooks/useTimeOffRequestPreview'
import TextField from './TextField'
import Spinner from './Spinner'
import Tooltip from './Tooltip'

interface Props {
  onSubmit(comments: string): Promise<void>
  afterLeave: BaseDialogProps['afterLeave']
  preview: ReturnType<typeof useTimeOffRequestPreview>
  onClose: BaseDialogProps['onClose']
  isOpen: BaseDialogProps['isOpen']
}

type RequestForm = {
  comments: string
}

const defaultValues = {
  comments: '',
}

export default function TimeOffRequestDialog(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const { preview } = props

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<RequestForm>({ defaultValues })

  const handleTimeOffRequest = (data: RequestForm) => {
    setIsSubmitting(true)
    props.onSubmit(data.comments).finally(() => setIsSubmitting(false))
  }

  const selectedCount = preview.data?.length

  const deductedCount =
    preview.data?.reduce(
      (count, { coveredByPtoPolicy }) =>
        coveredByPtoPolicy ? count : count + 1,
      0
    ) || 0

  const totalCost =
    preview.data?.reduce((sum, { dayValue }) => sum + (dayValue || 0), 0) || 0

  return (
    <BaseDialog
      noCloseButton
      afterLeave={() => {
        props.afterLeave?.()
        setIsSubmitting(false)
        reset()
      }}
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Confirm your request"
    >
      <p className="mt-4 max-w-prose text-sm text-slate-500">
        You will request{' '}
        <strong>
          {selectedCount} {selectedCount === 1 ? 'day' : 'days'}
        </strong>{' '}
        of time-off.
      </p>

      {preview.data && preview.data.length > 0 && (
        <ol
          className={classNames(
            (preview.data || []).length > 5 ? 'space-y-1' : 'space-y-2',
            'mt-5 list-decimal pl-7 marker:font-mono marker:text-xs marker:text-slate-400'
          )}
        >
          {preview.data.map((item) => {
            return (
              <li key={item.date.toString()}>
                <span
                  className={classNames(
                    (preview.data || []).length > 5
                      ? 'text-sm'
                      : 'text-base font-semibold',
                    item.coveredByPtoPolicy
                      ? 'text-slate-600'
                      : 'text-red-600/80'
                  )}
                >
                  {format(dateFromIsoString(item.date), 'PPPP')}
                </span>
                {!item.coveredByPtoPolicy && (
                  <Tooltip content="Not enough PTO to cover this date">
                    <span className="cursor-help px-2">
                      <ExclaimationTriangleIcon className="relative -top-0.5 inline-block h-5 w-5 text-red-600" />{' '}
                    </span>
                  </Tooltip>
                )}
              </li>
            )
          })}
        </ol>
      )}

      {deductedCount > 0 && (
        <div className="mt-6 rounded-md bg-orange-100 px-4 py-4 text-sm text-orange-800">
          <CurrencyDollarIcon className="relative -top-0.5 -ml-0.5 inline-block h-5 w-5 align-middle" />{' '}
          <strong className="font-semibold">Heads up!</strong>
          <div className="mt-2">
            <p>
              Your selection includes days that are not covered by our policy
              because you have not accrued enough PTO up to that date.
            </p>
            <p className="mt-2 font-semibold">
              This means the amount for the {deductedCount} extra{' '}
              {deductedCount === 1 ? 'day' : 'days'} will be deducted from your
              monthly payment.
            </p>

            {totalCost > 0 && (
              <p className="mt-2 text-lg font-semibold text-orange-800">
                Total cost: {currencyFormat.format(totalCost)}
              </p>
            )}
          </div>
        </div>
      )}

      {!preview.data && !preview.error && <Spinner className="mx-auto my-12" />}

      {preview.error && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">Oops...</strong>
          <p className="mt-2">
            {preview.error.info?.message || preview.error.message}
          </p>
        </div>
      )}

      <form
        onSubmit={handleSubmit(handleTimeOffRequest)}
        className="mt-6 max-w-prose"
      >
        <TextField
          label="Message (for Southteams admin)"
          multiline
          rows={3}
          errors={errors}
          {...register('comments', {
            required: 'Please, add a message to your request',
          })}
        />

        <div className="mt-3 flex gap-4">
          <button
            className="text-md block rounded-md bg-sky-500 px-8 py-2 font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2 disabled:bg-sky-300 md:w-auto"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting && (
              <span className="relative top-0.5 mr-2 inline-block">
                <Spinner reverse size="small" />
              </span>
            )}
            Request
          </button>
          <button
            className="block rounded-md px-4 py-2 text-slate-500 underline decoration-slate-400 underline-offset-1 outline-none hover:text-slate-700 focus-visible:ring-2 disabled:text-slate-300 md:w-auto"
            disabled={isSubmitting}
            onClick={props.onClose}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </BaseDialog>
  )
}
