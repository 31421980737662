import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'

import { classNames } from '../utils'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  title?: string
}

export default function ErrorBox({
  className,
  children,
  title,
  ...otherProps
}: Props) {
  return (
    <div
      className={classNames(
        className,
        'max-w-prose rounded-md bg-red-100 px-4 py-4 text-sm text-red-800'
      )}
      {...otherProps}
    >
      <ExclaimationCircleIcon className="relative -top-0.5 -ml-0.5 mr-1 inline-block h-5 w-5 align-middle" />
      <strong className="font-semibold">{title || 'Oops...'}</strong>
      <p className="mt-2">{children}</p>
    </div>
  )
}
