import { rest } from 'msw'

import { apiBaseUrl } from '../config'
import { Team } from '../types'

const fakeTeam: Team = {
  name: 'Mad Mobile',
  slack: 'https://south-teams.slack.com/',
  members: [
    {
      southEmail: 'wwhite@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Walter White',
      position: 'Backend Engineer',
    },
    {
      southEmail: 'sgraff@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Stefi Graff',
      position: 'UI Designer',
    },
    {
      southEmail: 'peters@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Peter Sellers',
      position: 'Project Manager',
    },
    {
      southEmail: 'neffron@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Nora Effron',
      position: 'Frontend Engineer',
    },
    {
      southEmail: 'jesse@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Jesse Pinkman',
      position: 'Backend Engineer',
    },
    {
      southEmail: 'bfett@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Boba Fett',
      position: 'DBA',
    },
    {
      southEmail: 'driverae@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Diego Rivera',
      position: 'UX Designer',
    },
    {
      southEmail: 'gtray@southteams.com',
      picture:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Gloria Traynor',
      position: 'UX analyst',
    },
  ],
}

const getTeamHandler = rest.get<null, {}, Team>(
  apiBaseUrl + '/my-team',
  (req, res, ctx) => {
    // return res(ctx.status(404), ctx.delay(1500))
    return res(ctx.status(200), ctx.json(fakeTeam), ctx.delay(500))
  }
)

export const handlers = [getTeamHandler]
