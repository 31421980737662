import { rest } from 'msw'

import { apiBaseUrl } from '../config'
import { Document } from '../types'

const fakeDocs: Document[] = [
  {
    title: 'Offer Letter',
    link: 'https://www.buds.com.ua/images/Lorem_ipsum1.pdf',
  },
  {
    title: 'Independent Contractor Agreement',
    link: 'https://www.buds.com.ua/images/Lorem_ipsum2.pdf',
  },
  {
    title: 'W-8 BEN Form',
    link: 'https://www.buds.com.ua/images/Lorem_ipsum3.pdf',
  },
]

const getDocumentsHandler = rest.get<null, {}, Document[]>(
  apiBaseUrl + '/docs',
  (req, res, ctx) => {
    // return res(ctx.status(404), ctx.delay(1500))
    return res(ctx.status(200), ctx.json(fakeDocs), ctx.delay(500))
  }
)

export const handlers = [getDocumentsHandler]
