export enum EnvironmentMode {
  development = 'development',
  testing = 'testing',
  staging = 'staging',
  production = 'production',
}

const getString = (name: string): string | null => {
  const value =
    typeof process.env[name] === 'string' ? process.env[name]!.trim() : null
  if (!value) {
    console.warn(`Environment variable ${name} is not present`)
  }
  return value
}

/**
 * Environmental variables used throughout the application.
 */
export const ENV_VARS = {
  /**
   * Current environment mode: development | testing | staging | production
   */
  NODE_ENV: getString('NODE_ENV'),
  /**
   * SWR: Revalidate on focus
   */
  REACT_APP_REVALIDATE_ON_FOCUS: getString('REACT_APP_REVALIDATE_ON_FOCUS'),
  /**
   * Google authenticator Sign in URL
   */
  REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID: getString(
    'REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID'
  ),
  /**
   * Contractors API base URL
   */
  REACT_APP_API_BASE_URL: getString('REACT_APP_API_BASE_URL'),
  /**
   * If true will use a mocked API instead of the contractors API base URL
   */
  REACT_APP_USE_MOCKED_API: getString('REACT_APP_USE_MOCKED_API'),
  /**
   * Auth API base URL
   */
  REACT_APP_AUTH_API_BASE_URL: getString('REACT_APP_AUTH_API_BASE_URL'),
  /**
   * Public URL used to load assets (images, etc)
   */
  PUBLIC_URL: getString('PUBLIC_URL'),
}

export const inProd = () => {
  return ENV_VARS.NODE_ENV === EnvironmentMode.production
}
