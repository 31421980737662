import * as React from 'react'
import formatISO from 'date-fns/formatISO'

import getTime from 'date-fns/getTime'

import ProfileForm, { FormData } from '../components/ProfileForm'
import { dateFromIsoString } from '../utils'
import { updateProfile } from '../api/profileApi'
import ErrorMessage from '../components/ErrorMessage'
import useProfile from '../hooks/useProfile'
import Spinner from '../components/Spinner'

export default function ProfilePage() {
  const { data, mutate, error } = useProfile()

  const handleSubmit = async (formData: FormData) => {
    if (!data) return
    const newData = {
      ...formData,
      birthdate: getTime(dateFromIsoString(formData.birthdate)),
    }
    return mutate(updateProfile(newData), {
      rollbackOnError: true,
      optimisticData: { ...data, ...newData },
    })
  }

  return (
    <div className="pb-12">
      <h1 className="text-3xl">My Profile</h1>

      {data ? (
        <ProfileForm
          defaultValues={{
            southEmail: data.southEmail,
            birthdate: data.birthdate
              ? formatISO(data.birthdate, { representation: 'date' })
              : '',
            whatsapp: data.whatsapp,
            linkedin: data.linkedin,
            address: data.address,
            email: data.email,
            name: data.name,
          }}
          onSubmit={handleSubmit}
        />
      ) : error ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <Spinner className="mx-auto my-28" />
      )}
    </div>
  )
}
