import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getAccounts } from '../api/accountsApi'
import { Account } from '../types'

export default function useAccounts(options?: SWRConfiguration) {
  return useSWR<Account[], Error>(
    'accounts',
    () =>
      getAccounts().then((d) =>
        d.sort((a, b) => (a.lastUpdateOn > b.lastUpdateOn ? -1 : 1))
      ),
    options
  )
}
