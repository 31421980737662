import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getNextPayment } from '../api/paymentsApi'
import { Payment } from '../types'

export default function useNextPayment(options?: SWRConfiguration) {
  return useSWR<Omit<Payment, '_id'>, Error>(
    'next-payment',
    getNextPayment,
    options
  )
}
