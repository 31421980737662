import { InformationCircleIcon } from '@heroicons/react/24/outline'
import type { NavLinkProps } from 'react-router-dom'
import { NavLink, Outlet } from 'react-router-dom'
import * as React from 'react'

import AdvisorSessionDialog from '../components/AdvisorSessionDialog'
import { classNames } from '../utils'
import { useToasts } from '../components/ToastsProvider'
import useProfile from '../hooks/useProfile'

function Tab({
  children,
  end,
  to,
}: {
  children: React.ReactNode
  end?: NavLinkProps['end']
  to: NavLinkProps['to']
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames(
          isActive
            ? 'bg-slate-100 text-slate-800'
            : 'text-slate-500 hover:text-sky-600',
          'my-1 -ml-3 block whitespace-nowrap rounded-md px-3 py-2 text-sm font-semibold outline-none focus-visible:ring-2'
        )
      }
      end={end}
      to={to}
    >
      {children}
    </NavLink>
  )
}

export default function PaymentsPage() {
  const [isAdvisorDialogOpen, setIsAdvisorDialogOpen] = React.useState(false)
  const { addToast } = useToasts()
  const profile = useProfile({
    onError: () => {
      addToast('Tax advisor benefit status could not be retrieved', {
        variant: 'warning',
      })
    },
    shouldRetryOnError: false,
  })

  return (
    <div className="pb-32">
      <h1 className="text-3xl">Payments</h1>

      <div className="flex flex-col items-start gap-4 xl:flex-row">
        {profile.data?.enableTaxAdvisorBenefit && (
          <aside className="order-1 max-w-prose shrink-0 rounded-md border border-slate-100 p-4 xl:mt-4 xl:max-w-[220px]">
            <h2 className="text-sm font-semibold text-slate-500">
              <InformationCircleIcon className="align-center relative top-[-1px] mr-0.5 inline-block w-5 text-sky-500" />{' '}
              Did you know?
            </h2>
            <p className="mt-2 text-sm text-slate-500">
              As a Southie you can request a session with our tax advisor every
              month.
            </p>
            <button
              className="mt-2 text-sm text-sky-600 underline underline-offset-2 outline-none hover:text-sky-700 focus-visible:ring-2"
              onClick={() => setIsAdvisorDialogOpen(true)}
            >
              Request a session
            </button>
          </aside>
        )}

        <div className="grow">
          <nav className="my-4">
            <ul className="flex gap-4 md:gap-8">
              <li>
                <Tab end to="">
                  History
                </Tab>
              </li>
              <li>
                <Tab end to="setup">
                  <span className="hidden md:inline">Payment setup</span>
                  <span className="inline md:hidden">Setup</span>
                </Tab>
              </li>
              <li>
                <Tab to="accounts">Accounts</Tab>
              </li>
              <li>
                <Tab to="invoicing">Invoicing</Tab>
              </li>
            </ul>
          </nav>

          <Outlet />
        </div>
      </div>

      <AdvisorSessionDialog
        onClose={() => setIsAdvisorDialogOpen(false)}
        isOpen={isAdvisorDialogOpen}
      />
    </div>
  )
}
