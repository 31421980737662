import {
  QuestionMarkCircleIcon,
  ChevronDownIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Menu, Transition } from '@headlessui/react'
import { ErrorMessage } from '@hookform/error-message'
import { useSWRConfig } from 'swr'
import * as React from 'react'

import { classNames, currencyFormat } from '../utils'
import { Account, PaymentSetup } from '../types'
import { updatePaymentSetup } from '../api/paymentSetupApi'
import AccountsCombo from './AccountsCombo'
import { useToasts } from './ToastsProvider'
import Spinner from './Spinner'
import Tooltip from './Tooltip'

interface Props {
  nextPaymentAmount: number
  paymentSetup: PaymentSetup | null
  accounts: Account[]
}

type FormData = {
  fixedToAccount?: Account
  fixedType: 'all' | 'custom'
  fixedAmount: number
  restToAccount?: Account
}

function getDefaultValues({
  nextPaymentAmount,
  paymentSetup,
  accounts,
}: Props): FormData {
  const fixedToAccount = accounts.find(
    ({ _id }) => _id === paymentSetup?.fixed.account
  )
  if (paymentSetup === null) {
    return {
      fixedToAccount,
      fixedType: 'all',
      fixedAmount: nextPaymentAmount,
    }
  }
  if ('rest' in paymentSetup && typeof paymentSetup.rest === 'string') {
    const restToAccount = accounts.find(({ _id }) => _id === paymentSetup.rest)
    return {
      fixedToAccount,
      fixedType: 'custom',
      fixedAmount: paymentSetup.fixed.amount,
      restToAccount,
    }
  }
  return {
    fixedToAccount,
    fixedType: 'all',
    fixedAmount: nextPaymentAmount,
  }
}

export default function PaymentSetupForm(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const { addToast } = useToasts()
  const { mutate } = useSWRConfig()

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    register,
    setValue,
    watch,
  } = useForm<FormData>({ defaultValues: getDefaultValues(props) })

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const newPaymentSetup: PaymentSetup =
      data.fixedType === 'all'
        ? { fixed: { amount: 'all', account: data.fixedToAccount?._id! } }
        : {
            fixed: {
              amount: data.fixedAmount,
              account: data.fixedToAccount?._id!,
            },
            rest: data.restToAccount?._id!,
          }
    setError(null)
    setIsSubmitting(true)
    mutate(
      'payment-setup',
      () =>
        updatePaymentSetup(newPaymentSetup)
          .then((response) => {
            addToast('Payment setup updated', { variant: 'success' })
            return response
          })
          .catch((err) => setError(err.message))
          .finally(() => setIsSubmitting(false)),
      {
        optimisticData: newPaymentSetup,
        rollbackOnError: false,
      }
    )
  }

  // move focus to custom amount field when split payment is selected
  const fixedType = watch('fixedType')
  React.useEffect(() => {
    if (fixedType !== 'custom') return
    window.setTimeout(() => {
      document.getElementById('fixed.amount')?.focus()
    }, 50)
  }, [fixedType])

  return (
    <div>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>

      <div className="mt-8 flex max-w-prose items-baseline justify-between gap-2">
        <span>
          Your next{' '}
          <span className="whitespace-nowrap">
            payment{' '}
            <Tooltip content="Payment will be processed within the first 10 days of the month.">
              <QuestionMarkCircleIcon
                className="-mt-1 inline-block w-5 rounded-full text-sky-500 outline-none hover:text-sky-700 focus-visible:ring-2"
                tabIndex={0}
              />
            </Tooltip>
          </span>
        </span>
        <span className="text-xl font-bold">
          {currencyFormat.format(props.nextPaymentAmount)}
        </span>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-6 max-w-prose">
        <ul className="space-y-3 sm:space-y-0">
          <li className="relative -mx-3 rounded-md bg-slate-50 px-3 py-2 sm:bg-transparent">
            <div className="sm:flex sm:items-baseline sm:gap-4">
              <div className="flex">
                <div className="grow items-baseline gap-2 sm:flex">
                  <label
                    className="whitespace-nowrap text-lg text-slate-600 "
                    htmlFor="fixedAmount"
                  >
                    {fixedType === 'all' ? 'Send' : 'Send up to'}
                  </label>
                  <div className="group flex">
                    <div
                      className={classNames(
                        fixedType === 'all' && 'hidden',
                        'relative flex grow sm:w-[160px]'
                      )}
                    >
                      <input
                        {...register('fixedAmount', {
                          required:
                            fixedType === 'custom'
                              ? 'Please, provide the amount you want us to send to this account.'
                              : false,
                          min: 1,
                          max: {
                            value: props.nextPaymentAmount,
                            message:
                              'Nice try! but the amount must be less than your next payment.',
                          },
                          valueAsNumber: true,
                        })}
                        placeholder="Insert an amount"
                        className="peer w-full border-0 border-b-2 border-gray-200 bg-white pb-0.5 pl-14 pt-1.5 text-lg placeholder:text-lg placeholder:italic placeholder-shown:pl-2 focus:ring-0 sm:bg-slate-50"
                        inputMode="decimal"
                        type="number"
                        id="fixed.amount"
                      />
                      <div className="absolute z-10 w-14 pb-0.5 pt-1.5 text-center text-lg text-slate-500 peer-placeholder-shown:hidden">
                        USD
                      </div>
                    </div>

                    <input {...register('fixedType')} type="hidden" />
                    <Menu
                      as="div"
                      className={classNames(
                        fixedType === 'all' ? 'w-[200px]' : '',
                        'relative'
                      )}
                    >
                      <Menu.Button
                        className={classNames(
                          fixedType === 'all'
                            ? 'border-l-transparent hover:bg-slate-100'
                            : 'hover:bg-slate-200',
                          'block h-[38px] w-full border-0 border-b-2 border-gray-200 bg-white px-3 pb-0.5 pt-1.5 text-lg outline-none focus-visible:ring-0 group-focus-within:border-b-blue-600 sm:bg-slate-50'
                        )}
                      >
                        <div className="flex items-center justify-between">
                          <span className="sr-only">Open type menu</span>
                          {fixedType === 'all' ? 'all' : ''}
                          <ChevronDownIcon
                            className="block h-4 w-4 text-slate-400"
                            aria-hidden="true"
                          />
                        </div>
                      </Menu.Button>
                      <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                        as={React.Fragment}
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'flex w-full justify-between px-4 py-2 text-left text-sm text-gray-700'
                                )}
                                onClick={() => setValue('fixedType', 'all')}
                                type="button"
                              >
                                all
                                {fixedType === 'all' && (
                                  <CheckIcon className="w- h-5" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'flex w-full justify-between px-4 py-2 text-left text-sm text-gray-700'
                                )}
                                onClick={() => setValue('fixedType', 'custom')}
                                type="button"
                              >
                                custom amount
                                {fixedType === 'custom' && (
                                  <CheckIcon className="w- h-5" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              <div className="mt-2 sm:mt-0 sm:flex sm:grow sm:items-baseline sm:gap-4">
                <label
                  className="text-lg text-slate-600"
                  htmlFor="fixedToAccount"
                >
                  to
                </label>
                <input
                  {...register('fixedToAccount._id', {
                    required: 'Please, provide an account.',
                  })}
                  type="hidden"
                />
                <AccountsCombo
                  disabledOption={
                    fixedType === 'custom' ? watch('restToAccount') : undefined
                  }
                  selected={watch('fixedToAccount')}
                  onSelect={(account) => setValue('fixedToAccount', account)}
                  accounts={props.accounts}
                />
              </div>
            </div>
            <ErrorMessage
              className="mt-2 text-sm text-red-600"
              errors={errors}
              name="fixedAmount"
              as="div"
            />
            <ErrorMessage
              className="mt-2 text-sm text-red-600"
              errors={errors}
              name="fixedToAccount._id"
              as="div"
            />
          </li>

          <li
            className={classNames(
              fixedType === 'all' && 'hidden',
              'relative -mx-3 mt-2 rounded-md bg-slate-50 px-3 py-2 sm:mt-0 sm:bg-transparent'
            )}
          >
            <div className="sm:flex sm:items-baseline sm:gap-4">
              <div className=" sm:flex sm:items-baseline sm:gap-4">
                <label
                  className="text-lg text-slate-600"
                  htmlFor="restToAccount"
                >
                  and the rest to
                </label>
                <input
                  {...register('restToAccount._id', {
                    required:
                      fixedType === 'custom'
                        ? 'Provide a secondary account or send the whole amount to the primary account.'
                        : false,
                    validate: () => {
                      const [fixedToAccount, restToAccount] = getValues([
                        'fixedToAccount._id',
                        'restToAccount._id',
                      ])
                      return fixedType === 'custom' &&
                        fixedToAccount === restToAccount
                        ? 'If you are going to use the same account please just use one payment.'
                        : true
                    },
                  })}
                  type="hidden"
                />
                <AccountsCombo
                  disabledOption={watch('fixedToAccount')}
                  selected={watch('restToAccount')}
                  onSelect={(account) => setValue('restToAccount', account)}
                  accounts={props.accounts}
                />
              </div>
              <button
                aria-label="Remove"
                className="absolute right-0 top-2 mx-2 rounded-sm outline-none outline-offset-2 hover:text-sky-500 focus-visible:ring-2 disabled:opacity-0 sm:static"
                onClick={() => setValue('fixedType', 'all')}
                type="button"
              >
                <XMarkIcon className="block w-5 sm:w-4" aria-hidden="true" />
              </button>
            </div>
            <ErrorMessage
              className="mt-2 text-sm text-red-600"
              errors={errors}
              name="restToAccount._id"
              as="div"
            />
          </li>
        </ul>

        {error && <div className="mt-4 text-sm text-red-600">{error}</div>}

        <button
          className="text-md mt-8 block rounded-md bg-sky-500 px-10 py-3 font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2 disabled:bg-sky-300 md:w-auto"
          disabled={isSubmitting}
          type="submit"
        >
          {isSubmitting && (
            <span className="relative top-0.5 mr-2 inline-block">
              <Spinner reverse size="small" />
            </span>
          )}
          {isSubmitting ? 'Saving...' : 'Save'}
        </button>
      </form>
    </div>
  )
}
